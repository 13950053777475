<template>
  <div class="auth-form-view">
    <div class="container l">
      <div class="row align-items-center justify-content-center"> <!-- Center align vertically and horizontally -->
        <!-- Left Image Column -->
        <!-- <div class="col-md-6"> -->
          <!-- <img src="../assets/auth.png" alt="Auth Image" class="img-fluid" /> -->
        <!-- </div> -->
    
        <!-- Right Form Column -->
        <div class="col-md-12">
          <div class="text-center">
            <img src="../assets/logo2.png" alt="Logo" class="img-fluid" style="width: 12%;">
          </div>
          <div class="auth-form register-form text-center" style="margin-top: 25px;">
            <div class="page-message" v-if="isGettingQuote()">
              Please login to get a quote.
            </div>
            <h1 class="mb-2" style="font-size: 36px; color:#fff;">Welcome!</h1>
            <p class="mb-2" style="font-size: 22px; margin-top: 10px; color:#fff;">Register your Account</p>
            <!-- <div class="tabs">
              <div><router-link to="/login">Login</router-link></div>
              <div><router-link to="/register">Register</router-link></div>
            </div> -->
    
            <RegisterForm />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RegisterForm from '@/components/auth/RegisterForm.vue';

export default {
  name: 'RegisterView',
  components: {
    RegisterForm,
  },
  methods : {
    isGettingQuote() {
      return this.$store.state.afterLoginRedirect != null && this.$store.state.afterLoginRedirect.path == '/quote';
    }
  }
};
</script>

<style lang="scss">
@import "../scss/partials/variables.scss";
@import '../scss/partials/auth-form-view.scss';
</style>

<style lang="scss">

.auth-form {
  margin-bottom: 50px !important;
}

.auth-form.register-form.text-center {
  background: rgb(248 249 250 / 12%) !important;
  border-radius: 89px !important;
  backdrop-filter: blur(10px);
  padding: 50px 0px;
}

.auth-form-view {
  background: linear-gradient(rgb(0 0 0 / 10%), rgb(0 0 0 / 9%)), url(/img/auth-bg.fca6e106.png) no-repeat center center;

background-size: cover;
min-height: 100vh;
.auth-form-view .auth-form .form-wrapper .inner-wrapper {
  background-color: #fff0 !important;
  padding: 30px 35px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  position: relative;
}
}

.auth-form-view .auth-form .form-wrapper .inner-wrapper {
  background-color: #fff0 !important;
  padding: 30px 35px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  position: relative;
}
</style>