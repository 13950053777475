<template>
    <div class="form-wrapper" :class="formState">
        <div class="inner-wrapper">
            <div class="form">
                <form
                    action=""
                    @focusout="change"
                    @input="change"
                    @submit="submit"
                    novalidate
                >
                    <div class="form-group">
                        <div
                            class="input-field-wrapper"
                            :class="{
                                invalid: validation.firstname.errorMessage,
                            }"
                        >
                            <label for="firstname"><img src="../../assets/solar_user.png" alt="firstname" class="img-fluid d-none d-md-block" style="width: 45%; padding-top: 24px;"></label>
                            <div class="input-field">
                                <input
                                    type="text"
                                    name="firstname"
                                    id="firstname"
                                    data-rules='["required"]'
                                    v-model="firstname"
                                    placeholder="First Name"
                                />
                            </div>
                        </div>
                        <p
                            class="error-message"
                            v-if="validation.firstname.errorMessage"
                        >
                            {{ validation.firstname.errorMessage }}
                        </p>
                    </div>

                    <div class="form-group">
                        <div
                            class="input-field-wrapper"
                            :class="{
                                invalid: validation.lastname.errorMessage,
                            }"
                        >
                            <label for="lastname"><img src="../../assets/solar_user.png" alt="lastname" class="img-fluid d-none d-md-block" style="width: 45%; padding-top: 24px;"></label>
                            <div class="input-field">
                                <input
                                    type="text"
                                    name="lastname"
                                    id="lastname"
                                    data-rules='["required"]'
                                    v-model="lastname"
                                    placeholder="Last Name"
                                />
                            </div>
                        </div>
                        <p
                            class="error-message"
                            v-if="validation.lastname.errorMessage"
                        >
                            {{ validation.lastname.errorMessage }}
                        </p>
                    </div>

                    <div class="form-group">
                        <div
                            class="input-field-wrapper"
                            :class="{ invalid: validation.email.errorMessage }"
                        >
                            <label for="email"><img src="../../assets/mage_email.png" alt="email" class="img-fluid d-none d-md-block" style="width: 45%; padding-top: 24px;"></label>
                            <div class="input-field">
                                <input
                                    type="email"
                                    name="email"
                                    id="email"
                                    data-rules='["required","email"]'
                                    v-model="email"
                                    placeholder="Email"
                                />
                            </div>
                        </div>
                        <p
                            class="error-message"
                            v-if="validation.email.errorMessage"
                        >
                            {{ validation.email.errorMessage }}
                        </p>
                    </div>

                    <div class="form-group">
                        <div
                            class="
                                input-field-wrapper
                                phone-input-field-wrapper
                            "
                            :class="{
                                invalid:
                                    validation.countryCode.errorMessage ||
                                    validation.phone.errorMessage,
                            }"
                        >
                            <label for="phone"><img src="../../assets/bi_phone.png" alt="phone" class="img-fluid d-none d-md-block" style="width: 45%; padding-top: 24px;"></label>
                            <div class="input-field-inner-wrapper">
                                <div class="country-code-dropdown">
                                    <select
                                        name="countryCode"
                                        placeholder="Search"
                                        ref="countryCodeDropdownEl"
                                        data-rules='["required"]'
                                        v-model="countryCode"
                                    ></select>
                                </div>
                                <div class="input-field">
                                    <input
                                        type="tel"
                                        name="phone"
                                        id="phone"
                                        data-rules='["required"]'
                                        v-model="phone"
                                        placeholder="Phone"
                                    />
                                </div>
                            </div>
                        </div>
                        <p
                            class="error-message"
                            v-if="
                                validation.countryCode.errorMessage ||
                                validation.phone.errorMessage
                            "
                        >
                            {{ validation.phone.errorMessage }}
                            <span v-if="!validation.phone.errorMessage">{{
                                validation.countryCode.errorMessage
                            }}</span>
                        </p>
                    </div>

                    <div class="form-group">
                        <div
                            class="input-field-wrapper"
                            :class="{ invalid: validation.email.errorMessage }"
                        >
                            <label for="password"><img src="../../assets/light_password.png" alt="password" class="img-fluid d-none d-md-block" style="width: 45%; padding-top: 24px;"></label>
                            <div class="input-field">
                                <input
                                    type="password"
                                    name="password"
                                    id="password"
                                    data-rules='["required", "password"]'
                                    v-model="password"
                                    placeholder="Password"
                                />
                            </div>
                        </div>

                        <p
                            class="error-message"
                            v-if="validation.password.errorMessage"
                        >
                            {{ validation.password.errorMessage }}
                        </p>
                    </div>

                    <div class="form-error" v-if="error">
                        <div class="error">{{ error }}</div>
                    </div>

                    <!-- <button type="submit" class="">Register</button> -->
                    <div class="d-flex justify-content-between mb-4 mt-4" style="padding: 0 20px;">
                        <router-link to="/login" class="btn btn-outline-dark register-login" style="font-size: 16px !important;">Login</router-link>
                        <button type="submit" class="btn btn-dark register-button" style="font-size: 16px !important;">Sign Up</button>
                    </div>
                </form>
                <!-- <div class="other-login">
                    <p class="text-white">Or login with:</p>
                    <img src="../../assets/devicon_google.png" alt="google" class="img-fluid" style="width: 10%; background: #fff; border-radius: 40px; padding: 10px;">
                </div> -->
                <googleAuth/>
            </div>
            <div class="loading-overlay">
                <div class="loader"></div>
            </div>
        </div>
    </div>
</template>

<script>
/* global gtag */

import * as TomSelect from "tom-select";
import formValidation from "@/mixins/formValidation";
import googleAuth from '@/components/auth/googleAuth.vue';

export default {
    name: "RegisterForm",
    mixins: [formValidation],
    components: { googleAuth },
    data() {
        return {
            validation: {
                inputElements: [],
                firstname: {},
                lastname: {},
                email: {},
                countryCode: {},
                phone: {},
                password: {},
            },
            firstname: "",
            lastname: "",
            email: "",
            countryCode: "",
            phone: "",
            password: "",
        };
    },
    mounted() {
        this.$store.dispatch("fetchCountries").then(() => {
            this.initCountryCodeDropdown();
        });

        this.initValidation();

        //this.error = this.$route.query['error-message'];
    },
    methods: {
        initCountryCodeDropdown() {
            const countries = JSON.parse(
                JSON.stringify(this.$store.state.countries)
            );
            const flagsBaseUrl = this.$store.state.flagsBaseUrl;

            const defaultValue = "AE";
            this.countryCode = defaultValue;

            new TomSelect(this.$refs.countryCodeDropdownEl, {
                valueField: "code",
                searchField: ["name", "dialCode"],
                options: countries,
                maxOptions: 300,
                render: {
                    option: function (data, escape) {
                        return (
                            '<div class="country-code">' +
                            '<div class="flag-wrapper">' +
                            '<div class="flag" style="background-image: url(\'' +
                            flagsBaseUrl +
                            data.flag +
                            "')\"></div>" +
                            "</div>" +
                            '<div class="name">' +
                            escape(data.dialCode) +
                            "</div>" +
                            "</div>"
                        );
                    },
                    item: function (data, escape) {
                        return (
                            '<div class="country-code">' +
                            '<div class="flag-wrapper">' +
                            '<div class="flag" style="background-image: url(\'' +
                            flagsBaseUrl +
                            data.flag +
                            "')\"></div>" +
                            "</div>" +
                            '<div class="name">' +
                            escape(data.dialCode) +
                            "</div>" +
                            "</div>"
                        );
                    },
                },
                items: defaultValue,
            });
        },
        send() {
            this.error = null;
            this.success = null;

            this.$store
                .dispatch("register", {
                    firstName: this.firstname,
                    lastName: this.lastname,
                    countryCode: this.countryCode,
                    mobile: this.phone,
                    email: this.email,
                    password: this.password,
                })
                .then(() => {
                    //(response)

                    if (typeof gtag === 'function') {
                        //Event snippet for Sign-up conversion page
                        gtag("event", "conversion", {
                            send_to: process.env.VUE_APP_GOOGLE_ADS_SIGNUP_ID,
                        });
                    }

                    this.formState = null;
                    let redirectPath = "/addresses";
                    if (this.$store.state.afterLoginRedirectPath != null) {
                        redirectPath = this.$store.state.afterLoginRedirectPath;
                    }
                    this.$router.push({ path: redirectPath });
                    //this.success = 'Your account is created';
                })
                .catch((error) => {
                    this.formState = null;
                    this.error = error;
                });
        },
    },
};
</script>

<style lang="scss">
@import "../../scss/partials/variables.scss";
@import "../../../node_modules/tom-select/dist/css/tom-select.css";
@import "../../scss/partials/tom-select-overrides.scss";
</style>

<style lang="scss">

.auth-form-view .auth-form .form-wrapper {
  background-color: #e5e5e500 !important;
  padding: 15px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.auth-form-view .auth-form .form-wrapper .inner-wrapper {
  background-color: #ffffff00;
  padding: 0px 35px !important;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  position: relative;
}

button.btn.btn-dark {
  background-color: #474747;
  width: 47%;
  box-shadow: 0 2px 30px 0 rgb(231 231 231 / 61%) !important;
  font-weight: 400;
}

button.btn.btn-dark:hover {
  background-color: transparent;
  color: #474747;
  width: 47%;
  box-shadow: 0 2px 30px 0 rgb(231 231 231 / 61%) !important;
  font-weight: 400;
  border: 1px solid;
}

button.btn.btn-dark.register-button {
    width: 46% !important;
  }

a.btn.btn-outline-dark {
  background-image: linear-gradient(to right, #1985A1 0%, #024455 100%);
  height: 55px;
  border: 0px none;
  color: #fff;
  font-size: 17px;
  border: 1px solid;
  width: 47%;
  line-height: 40px;
}
a.btn.btn-outline-dark:hover {
  background-color: #474747;
  width: 47%;
  box-shadow: 0 2px 30px 0 rgb(231 231 231 / 61%) !important;
  font-weight: 400;
  color: #fff;
}

a.btn.btn-outline-dark.register-login{
  height: 40px !important;
}

.auth-form .input-field-wrapper {
  height: 50px !important;
  border-radius: 5px;
  background-color: #fbfbfb !important;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
  box-shadow: 0 2px 5px 0 rgb(203 203 203 / 61%) !important;
}

.auth-form .input-field-wrapper label:after {
  display: none !important;
}

.auth-form .input-field-wrapper input[type=text], .auth-form .input-field-wrapper input[type=email], .auth-form .input-field-wrapper input[type=password], .auth-form .input-field-wrapper input[type=tel] {
  font-size: 13px !important;
}

.auth-form .input-field-wrapper label {
  width: 60px !important;
}

@media (max-width: 590px) {
  .auth-form .input-field-wrapper label {
      height: 0px !important;
  }
}

.auth-form .phone-input-field-wrapper .input-field-inner-wrapper .country-code-dropdown {
    width: 107px !important;
}

.auth-form .country-code .name {
    font-size: 15px !important;
}

.auth-form .country-code .flag-wrapper .flag {
    width: 20px !important;
    height: 13px !important;
}

</style>